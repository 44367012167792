import * as AllIcons from '@coreui/icons';

import { logo } from './logo';
import { logoNegative } from './logo-negative';
import { sygnet } from './sygnet';
import { logoNav } from './logoNav';

export const icons = {
  logo,
  logoNegative,
  sygnet,
  logoNav,
  ...AllIcons,
};
