import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { RecoilRoot } from 'recoil';
import App from './App';

import Styles from './styles';

import { registerServiceWorker } from './serviceWorker';

import HooksProvider from './hooks';

import store from './store';

import { icons } from './assets/icons';

React.icons = icons;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <RecoilRoot>
        <HooksProvider>
          <Styles />
            <App />
        </HooksProvider>
      </RecoilRoot>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

registerServiceWorker();
