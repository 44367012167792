/* eslint-disable import/order */
import React from 'react';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import ScrollToTop from './components/ScrollToTop';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import { useAuth } from './hooks/auth';

import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

const Home = React.lazy(() => import('./containers/Home/TheLayout'));
const Authenticate = React.lazy(
  () => import('./containers/Authenticate/TheLayout'),
);
const Admin = React.lazy(() => import('./containers/Admin/TheLayout'));
const Partner = React.lazy(() => import('./containers/Clients/TheLayout'));

const App: React.FC = () => {
  return (
    <Router>
      <ToastContainer />
      <ScrollToTop />
      <React.Suspense fallback={loading}>
        <Switch>
          <LoggedRoute path="/authenticate" name="Authenticate">
            <Authenticate />
          </LoggedRoute>

          <PrivateRoute path="/admin" name="Admin">
            <AdminRoute>
              <Admin />
            </AdminRoute>
          </PrivateRoute>

          <PrivateRoute path="/partner" name="Partner">
            <PartnerRoute>
              <Partner />
            </PartnerRoute>
          </PrivateRoute>
          <Route path="/" name="Home" component={Home} />
        </Switch>
      </React.Suspense>
    </Router>
  );
};

const PrivateRoute: React.FC<any> = ({ children, ...rest }) => {
  const { user } = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/authenticate/loginClient',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

const LoggedRoute: React.FC<any> = ({ children, ...rest }) => {
  const { user } = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!user) {
          return children;
        }

        if (user.role === 'Admin' || user.role === 'Legal') {
          return (
            <Redirect
              to={{
                pathname: '/admin/clients',
                state: { from: location },
              }}
            />
          );
        }

        if (user.role === 'User') {
          return (
            <Redirect
              to={{
                pathname: '/partner/profile',
                state: { from: location },
              }}
            />
          );
        }

        return null;
      }}
    />
  );
};

const AdminRoute: React.FC<any> = ({ children }) => {
  const { user } = useAuth();

  if (user.role === 'User') {
    return (
      <Redirect
        to={{
          pathname: '/partner/profile',
        }}
      />
    );
  }

  return children;
};

const PartnerRoute: React.FC<any> = ({ children }) => {
  const { user } = useAuth();

  if (user.role === 'Admin') {
    return (
      <Redirect
        to={{
          pathname: '/admin/clients',
        }}
      />
    );
  }

  return children;
};

export default App;
