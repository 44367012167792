import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import { toast } from 'react-toastify';
import api from '../services/api';

interface User {
  id: string;
  name: string;
  email: string;
  cpf: string;
  phone: string;
  cep: string;
  endereco: string;
  numEndereco: string;
  bairro: string;
  cidade: string;
  estado: string;
  role: string;
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  cpf: string;
  password: string;
  next?: () => void;
}

interface AuthContextData {
  user: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: User): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Coin:token');
    const user = localStorage.getItem('@Coin:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  // eslint-disable-next-line consistent-return
  async function handleVerifyUserTokenJWT() {
    try {
      const token = localStorage.getItem('@Coin:token');
      const response = await api.post(`/auth/jwt/token/${token}`);
      return response?.data;
    } catch (err) {
      if (err?.response?.data?.auth === false) {
        localStorage.removeItem('@Coin:token');
        localStorage.removeItem('@Coin:user');
        setData({} as AuthState);
      }
    }
  }

  useEffect(() => {
    handleVerifyUserTokenJWT();
  }, []);

  const signIn = useCallback(async ({ cpf, password, next }) => {
    try {
      const response = await api.post('/auth/login', {
        cpf,
        password,
      });

      const { token, user } = response.data;

      localStorage.setItem('@Coin:token', token);
      localStorage.setItem('@Coin:user', JSON.stringify(user));

      api.defaults.headers.authorization = `Bearer ${token}`;

      setData({ token, user });

      if (response.data.user === null) {
        toast.error('Usuario não encontrado!');
      }
      if (next) {
        next();
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message);
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@Coin:token');
    localStorage.removeItem('@Coin:user');
    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (user: User) => {
      localStorage.setItem('@Coin:user', JSON.stringify(user));

      setData({
        token: data.token,
        user,
      });
    },
    [setData, data.token],
  );

  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, signOut, updateUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  return context;
}

export { AuthProvider, useAuth };
