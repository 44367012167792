export const logo = [
  '108.126 36.832',
  `
  <svg xmlns="http://www.w3.org/2000/svg" width="108.126" height="36.832" viewBox="0 0 108.126 36.832">
  <g id="Grupo_8" data-name="Grupo 8" transform="translate(-97.754 -167.86)">
    <g id="Grupo_976" data-name="Grupo 976" transform="translate(-143.707 -362.644)">
      <path id="Caminho_622" data-name="Caminho 622" d="M362.007,1567.865h0a8.593,8.593,0,0,1-5.793-2.24l-6.92-6.918h-2.26l9.257,9.257a8.5,8.5,0,0,0,11.46-.028l.513-.513,8.716-8.716h-2.261l-6.882,6.884A8.591,8.591,0,0,1,362.007,1567.865Z" transform="translate(-102.967 -1002.838)" fill="#ffe44d"/>
      <path id="Caminho_623" data-name="Caminho 623" d="M472.587,1559.065l-5.606,5.606a3.522,3.522,0,0,1-5.061-.083l-.971-.993-3.34-3.34-1.091-1.092-.1-.1-.043-.042a1.3,1.3,0,0,0-.773-.313h-3.479l6.75,6.748a8.356,8.356,0,0,0,5.631,2.175h0a8.355,8.355,0,0,0,5.665-2.208l6.712-6.715h-3.939Z" transform="translate(-205.464 -1002.838)" fill="#ffe44d"/>
      <path id="Caminho_624" data-name="Caminho 624" d="M257.964,649.911a4.214,4.214,0,0,1,.79-4.175,4.139,4.139,0,0,1,.4-.4l8.319-8.322c.072-.079.154-.161.239-.239a3.578,3.578,0,0,1,2.41-.932h0a3.576,3.576,0,0,1,2.406.927l.01.01c.08.072.161.153.236.236l5.725,5.723h4.239l-6.871-6.871a8.5,8.5,0,0,0-11.457-.031l0,0-.57.57,0,0-8.552,8.552-.32.32-.355.355a7.713,7.713,0,0,0-1.78,3.545,7.6,7.6,0,0,0,1.318,3.52h4.1a4.113,4.113,0,0,1-.281-2.8Z" transform="translate(-11.084 -100.584)" fill="#ffe44d"/>
      <path id="Caminho_625" data-name="Caminho 625" d="M475.46,733.953c-.075-.082-.15-.157-.229-.229a3.344,3.344,0,0,0-2.254-.872h0a3.344,3.344,0,0,0-2.252.871c-.08.073-.156.149-.227.227l-8.332,8.334a3.977,3.977,0,0,0-1.222,4,4.065,4.065,0,0,1,.756-1.276,4.016,4.016,0,0,1,.389-.389l2.561-2.561.319-.319,1.33-1.33,4.113-4.113q.112-.123.235-.235a3.467,3.467,0,0,1,4.662,0l0,0q.122.111.232.232l5.762,5.762h6.833l-2.312-2.312h-4.571Z" transform="translate(-213.94 -197.356)" fill="#ffe44d"/>
      <path id="Caminho_626" data-name="Caminho 626" d="M359.686,1466.272h3.94l-1.1-1.1h-3.939Z" transform="translate(-114.234 -911.608)" fill="#ffe44d"/>
      <path id="Caminho_627" data-name="Caminho 627" d="M252.676,1298.866q-.125-.116-.24-.242h0a4.08,4.08,0,0,1-.527-.727h-4.347l-.035-.05a7.842,7.842,0,0,1-1.28-2.946,7.841,7.841,0,0,0-.044.831,7.721,7.721,0,0,0,1.419,4.472h2.122l-1.336-1.336Z" transform="translate(-4.624 -745.538)" fill="#ffe44d"/>
      <path id="Caminho_628" data-name="Caminho 628" d="M390.092,1509.829h-.332l1.206,1.206h.332Z" transform="translate(-144.64 -955.165)" fill="#ffe44d"/>
      <path id="Caminho_629" data-name="Caminho 629" d="M528.026,1455.469l-6,6a3.381,3.381,0,0,1-2.407,1h0a3.41,3.41,0,0,1-2.158-.771,3.489,3.489,0,0,1-.328-.307l-.972-.993-4.432-4.431-.143-.143-.006-.006a1.417,1.417,0,0,0-.85-.34h-2.086l.035.032h0l.2.2h1.842a1.179,1.179,0,0,1,.7.282l4.571,4.57.969.99a3.641,3.641,0,0,0,2.656,1.151h0a3.616,3.616,0,0,0,2.573-1.067l5.928-5.93h6.2l-2.311,2.312h.332l2.546-2.547Z" transform="translate(-260.593 -902.145)" fill="#ffe44d"/>
      <path id="Caminho_630" data-name="Caminho 630" d="M241.7,547.768a7.609,7.609,0,0,1,1.908-5.051l9.226-9.229.006-.006.566-.566h0a8.36,8.36,0,0,1,5.633-2.177h0a8.352,8.352,0,0,1,5.661,2.2l9.219,9.216h-2.262l.235.235h2.595l-9.624-9.62a8.588,8.588,0,0,0-5.823-2.267h0a8.6,8.6,0,0,0-5.788,2.236l0,0-9.13,9.133-.68.68a7.888,7.888,0,0,0-1.811,6.805q.041-.385.119-.759A7.752,7.752,0,0,1,241.7,547.768Z" transform="translate(0)" fill="#ffe44d"/>
    </g>
    <g id="Grupo_7" data-name="Grupo 7" transform="translate(139.382 169.206)">
      <path id="Caminho_6" data-name="Caminho 6" d="M751.526,306.232l2.2-2.2a10.078,10.078,0,0,0-5.784-1.772,10.241,10.241,0,1,0,5.782,18.665l-2.2-2.2a7.061,7.061,0,0,1-3.588.955,7.2,7.2,0,1,1,.006-14.408A7.066,7.066,0,0,1,751.526,306.232Z" transform="translate(-737.667 -295.043)" fill="#fff"/>
      <path id="Caminho_7" data-name="Caminho 7" d="M999.812,305.37a10.232,10.232,0,1,0,3,7.241A9.908,9.908,0,0,0,999.812,305.37ZM997.678,317.7a7.047,7.047,0,0,1-5.111,2.085,7.137,7.137,0,0,1-7.173-7.179,7.047,7.047,0,0,1,2.089-5.11,6.906,6.906,0,0,1,5.09-2.119,7.232,7.232,0,0,1,7.229,7.235A6.907,6.907,0,0,1,997.678,317.7Z" transform="translate(-966.443 -295.138)" fill="#fff"/>
      <path id="Caminho_8" data-name="Caminho 8" d="M1342.166,191.489a1.568,1.568,0,1,0,.442,1.091A1.471,1.471,0,0,0,1342.166,191.489Z" transform="translate(-1300.464 -191.047)" fill="#fff"/>
      <rect id="Retângulo_3" data-name="Retângulo 3" width="3.065" height="20.482" transform="translate(39.073 7.234)" fill="#fff"/>
      <rect id="Retângulo_4" data-name="Retângulo 4" width="3.065" height="20.482" transform="translate(45.955 7.237)" fill="#fff"/>
      <path id="Caminho_9" data-name="Caminho 9" d="M1456.56,302.56a10.248,10.248,0,0,0-10.245,10.209l0,10.269h3.065l0-10.269a7.015,7.015,0,0,1,2.089-5.082,6.906,6.906,0,0,1,5.09-2.119,7.231,7.231,0,0,1,7.229,7.207l0,10.269h3.009l0-10.269a10.247,10.247,0,0,0-10.237-10.217Z" transform="translate(-1400.299 -295.319)" fill="#fff"/>
    </g>
  </g>
</svg>
`,
];
