export const logoNav = [
  '108.041 36.846',
  `<svg xmlns="http://www.w3.org/2000/svg" width="108.041" height="36.846" viewBox="0 0 108.041 36.846">
  <g id="Grupo_8" data-name="Grupo 8" transform="translate(-97.839 -167.689)">
    <g id="Grupo_6" data-name="Grupo 6" transform="translate(97.839 167.689)">
      <g id="Grupo_2" data-name="Grupo 2" transform="translate(0.117 2.545)">
        <path id="Caminho_2" data-name="Caminho 2" d="M167.924,558.508l-8.719,8.719-.513.513a8.5,8.5,0,0,1-11.465.028l-9.26-9.26h6.074a1.3,1.3,0,0,1,.773.313l.043.042.1.1,1.092,1.092,3.341,3.341.971.993a3.523,3.523,0,0,0,5.063.083l5.608-5.608.357-.357Z" transform="translate(-135.479 -535.678)" fill="#ffe44d"/>
        <path id="Caminho_3" data-name="Caminho 3" d="M132.276,218.548H125.44l-5.764-5.764q-.111-.122-.233-.232l0,0a3.468,3.468,0,0,0-4.664,0q-.123.112-.235.235l-4.115,4.115-1.331,1.331-.319.319-2.562,2.562a4.091,4.091,0,0,0-.337,5.822h0q.131.144.275.275h0l1.3,1.3h-6.4a7.746,7.746,0,0,1,.525-9.609l.355-.355.32-.32,8.555-8.555,0,0,.571-.571,0,0a8.5,8.5,0,0,1,11.462.03Z" transform="translate(-99.641 -206.888)" fill="#ffe44d"/>
      </g>
      <g id="Grupo_5" data-name="Grupo 5" transform="translate(0)">
        <g id="Grupo_3" data-name="Grupo 3" transform="translate(2.323 22.828)">
          <path id="Caminho_4" data-name="Caminho 4" d="M148.884,530.974a8.6,8.6,0,0,1-5.8-2.24l-9.466-9.463h6.357a1.418,1.418,0,0,1,.85.341l.006.006.144.143,4.434,4.433.972.993a3.406,3.406,0,0,0,2.487,1.079h0a3.382,3.382,0,0,0,2.408-1l6-6h6.866l-9.431,9.435a8.6,8.6,0,0,1-5.83,2.274Zm-14.694-11.468,9.061,9.058a8.359,8.359,0,0,0,5.633,2.175h0a8.356,8.356,0,0,0,5.668-2.208l9.027-9.03h-6.2l-5.93,5.932a3.616,3.616,0,0,1-2.574,1.067h0a3.642,3.642,0,0,1-2.657-1.152l-.97-.991-4.573-4.571a1.181,1.181,0,0,0-.7-.282Z" transform="translate(-133.622 -519.266)" fill="#0a1932"/>
        </g>
        <g id="Grupo_4" data-name="Grupo 4">
          <path id="Caminho_5" data-name="Caminho 5" d="M99.316,189.551l-.035-.05a7.864,7.864,0,0,1,.531-9.755l.68-.681,9.133-9.136,0,0a8.6,8.6,0,0,1,5.79-2.237h0a8.591,8.591,0,0,1,5.826,2.268l9.627,9.624h-7.168l-5.8-5.8c-.075-.082-.15-.157-.229-.229a3.346,3.346,0,0,0-2.255-.873h0a3.345,3.345,0,0,0-2.253.871c-.08.073-.156.149-.227.227l-8.335,8.338a3.974,3.974,0,0,0-.331,5.65l.006.006c.081.089.166.175.253.255h0l1.52,1.52Zm9.9-18.882-.006.006-9.229,9.232a7.629,7.629,0,0,0-.545,9.409h6.052l-1.106-1.106c-.091-.083-.185-.176-.274-.274h0l0,0h0a4.2,4.2,0,0,1,.341-5.988l8.322-8.325c.072-.079.154-.161.239-.239a3.58,3.58,0,0,1,2.411-.932h0a3.577,3.577,0,0,1,2.407.928l.01.01c.08.072.161.153.236.236l5.727,5.725h6.5l-9.223-9.219a8.355,8.355,0,0,0-5.663-2.2h0a8.363,8.363,0,0,0-5.635,2.178h0Z" transform="translate(-97.839 -167.689)" fill="#0a1932"/>
        </g>
      </g>
    </g>
    <g id="Grupo_7" data-name="Grupo 7" transform="translate(139.382 169.206)">
      <path id="Caminho_6" data-name="Caminho 6" d="M751.526,306.232l2.2-2.2a10.078,10.078,0,0,0-5.784-1.772,10.241,10.241,0,1,0,5.782,18.665l-2.2-2.2a7.061,7.061,0,0,1-3.588.955,7.2,7.2,0,1,1,.006-14.408A7.066,7.066,0,0,1,751.526,306.232Z" transform="translate(-737.667 -295.043)" fill="#0a1932"/>
      <path id="Caminho_7" data-name="Caminho 7" d="M999.812,305.37a10.232,10.232,0,1,0,3,7.241A9.908,9.908,0,0,0,999.812,305.37ZM997.678,317.7a7.047,7.047,0,0,1-5.111,2.085,7.137,7.137,0,0,1-7.173-7.179,7.047,7.047,0,0,1,2.089-5.11,6.906,6.906,0,0,1,5.09-2.119,7.232,7.232,0,0,1,7.229,7.235A6.907,6.907,0,0,1,997.678,317.7Z" transform="translate(-966.443 -295.138)" fill="#0a1932"/>
      <path id="Caminho_8" data-name="Caminho 8" d="M1342.166,191.489a1.568,1.568,0,1,0,.442,1.091A1.471,1.471,0,0,0,1342.166,191.489Z" transform="translate(-1300.464 -191.047)" fill="#0a1932"/>
      <rect id="Retângulo_3" data-name="Retângulo 3" width="3.065" height="20.482" transform="translate(39.073 7.234)" fill="#0a1932"/>
      <rect id="Retângulo_4" data-name="Retângulo 4" width="3.065" height="20.482" transform="translate(45.955 7.237)" fill="#0a1932"/>
      <path id="Caminho_9" data-name="Caminho 9" d="M1456.56,302.56a10.248,10.248,0,0,0-10.245,10.209l0,10.269h3.065l0-10.269a7.015,7.015,0,0,1,2.089-5.082,6.906,6.906,0,0,1,5.09-2.119,7.231,7.231,0,0,1,7.229,7.207l0,10.269h3.009l0-10.269a10.247,10.247,0,0,0-10.237-10.217Z" transform="translate(-1400.299 -295.319)" fill="#0a1932"/>
    </g>
  </g>
</svg>`,
];
