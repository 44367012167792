export const sygnet = [
  '18.997 21.188',
  `
  <svg id="Grupo_977" data-name="Grupo 977" xmlns="http://www.w3.org/2000/svg" width="18.997" height="21.188" viewBox="0 0 18.997 21.188">
    <g id="Grupo_954" data-name="Grupo 954" transform="translate(0.067 1.464)">
      <path id="Caminho_595" data-name="Caminho 595" d="M678.194,3160.509l-5.014,5.014-.295.295a4.887,4.887,0,0,1-6.593.016l-5.325-5.325h3.493a.749.749,0,0,1,.445.18l.025.024.058.058.628.628,1.922,1.921.559.571a1.994,1.994,0,0,0,.194.182,2.026,2.026,0,0,0,2.717-.134l3.225-3.225.205-.206Z" transform="translate(-659.536 -3147.379)" fill="#ffe44d"/>
      <path id="Caminho_596" data-name="Caminho 596" d="M641.408,2815.593h-3.931l-3.315-3.315c-.042-.047-.087-.091-.134-.134v0a2,2,0,0,0-2.682,0c-.047.043-.092.088-.135.135l-2.366,2.366-.765.765-.183.184-1.473,1.474a2.256,2.256,0,0,0-.224.224,2.353,2.353,0,0,0,.03,3.124h0a2.123,2.123,0,0,0,.158.158h0l.75.75h-3.679a4.455,4.455,0,0,1,.3-5.526l.2-.2.184-.184,4.92-4.92h0l.328-.328h0a4.886,4.886,0,0,1,6.591.017Z" transform="translate(-622.641 -2808.888)" fill="#ffe44d"/>
    </g>
    <g id="Grupo_957" data-name="Grupo 957" transform="translate(0 0)">
      <g id="Grupo_955" data-name="Grupo 955" transform="translate(1.336 13.127)">
        <path id="Caminho_597" data-name="Caminho 597" d="M665.4,3128a4.943,4.943,0,0,1-3.333-1.288l-5.444-5.442h3.656a.812.812,0,0,1,.489.2l0,0,.083.082,2.55,2.549.559.571a2,2,0,0,0,.189.177,1.96,1.96,0,0,0,1.242.444h0a1.944,1.944,0,0,0,1.385-.574l3.45-3.451h3.949l-5.424,5.426A4.945,4.945,0,0,1,665.4,3128Zm-8.45-6.595,5.211,5.209a4.806,4.806,0,0,0,3.239,1.251h0a4.8,4.8,0,0,0,3.259-1.27l5.191-5.193h-3.567l-3.41,3.412a2.078,2.078,0,0,1-1.48.613h0a2.1,2.1,0,0,1-1.528-.662l-.558-.57-2.63-2.63a.682.682,0,0,0-.4-.161Z" transform="translate(-656.622 -3121.266)" fill="#ffe44d"/>
      </g>
      <g id="Grupo_956" data-name="Grupo 956">
        <path id="Caminho_598" data-name="Caminho 598" d="M621.689,2782.262l-.02-.029a4.522,4.522,0,0,1,.305-5.61l.391-.391,5.252-5.254h0a4.947,4.947,0,0,1,3.33-1.287h0a4.942,4.942,0,0,1,3.35,1.3l5.536,5.534h-4.122l-3.335-3.333c-.043-.047-.086-.09-.132-.132a1.923,1.923,0,0,0-1.3-.5h0a1.922,1.922,0,0,0-1.3.5c-.046.042-.09.085-.131.13l-4.793,4.794a2.4,2.4,0,0,0-.217.217,2.287,2.287,0,0,0,.027,3.032l0,0c.047.051.1.1.146.146h0l.874.874Zm5.694-10.859,0,0-5.307,5.309a4.387,4.387,0,0,0-.313,5.411h3.48l-.636-.636c-.053-.048-.106-.1-.157-.157h0a2.418,2.418,0,0,1,.2-3.444l4.786-4.788c.041-.046.088-.092.137-.137a2.057,2.057,0,0,1,1.387-.536h0a2.059,2.059,0,0,1,1.384.533l.006.006c.046.042.092.088.136.136l3.293,3.292h3.74l-5.3-5.3a4.8,4.8,0,0,0-3.257-1.268h0a4.808,4.808,0,0,0-3.24,1.253h0Z" transform="translate(-620.839 -2769.689)" fill="#ffe44d"/>
      </g>
    </g>
  </svg>
`,
];
