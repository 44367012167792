import { createStore } from 'redux';

export interface Istate {
  sidebarShow: 'responsive' | true | false | '';
}

const initialState: Istate = {
  sidebarShow: 'responsive',
};

const changeState = (
  state: Istate = initialState,
  { type, ...rest }: { type: 'set' },
) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest };
    default:
      return state;
  }
};

const store = createStore(changeState);
export default store;
